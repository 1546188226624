import axios from 'axios';
import Config from '../../Config';
import {
  buildEventMessage,
  generateUniqueId,
  getRequestHeaders,
} from './utils';

/**
 * Tracks an event by sending a log to the Sibly Wormhole API.
 *
 * @param {string} eventName - The name of the event to track.
 * @param {Record<string, unknown>} [metadata={}] - Additional metadata to include with the event.
 */
export const track = async (
  eventName: string,
  metadata: Record<string, unknown> = {},
): Promise<void> => {
  const { host, apiKey, source } = Config.wormhole;
  const url = `${host}/send`;

  const data = {
    source,
    sourceMessageType: 'logEvent',
    sourceReference: generateUniqueId(),
    message: buildEventMessage(eventName, metadata),
  };

  try {
    await axios.post(url, data, {
      headers: getRequestHeaders(apiKey),
    });
  } catch (error) {
    console.warn(error);
  }
};
