import Store from '@store';
import { coachTimezoneAbbreviation } from '@utils/dates';
import getBrowser from '@utils/getBrowser';
import { getAppVersion, getDeviceModel, getDeviceOS, getDeviceType } from '@utils/userAgentData';
import { omit } from 'lodash';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import Config from '../../Config';

/**
 * Generates common headers for API requests.
 */
export const getRequestHeaders = (apiKey: string) => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  'x-api-key': apiKey,
});

/**
 * Collects device and app information for the event log.
 */
export const getEventInfo = (metadata: Record<string, unknown> = {}) => ({
    ...omit(metadata, 'local_time', 'timezone', 'memberId'),
    ...(Store.member?.channel?.url
      ? { channel_url: Store.member?.channel?.url }
      : {}),
    release_version: getAppVersion(),
    device_type: getDeviceType(),
    device_model: getDeviceModel(),
    window_width: window.innerWidth,
    window_height: window.innerHeight,
    os: getDeviceOS(),
    is_emulator: false,
    client_type: 'browser',
    time_zone: coachTimezoneAbbreviation,
    play_sound_notifications: Store.playSoundNotifications,
    environment: Config.env,
    browser: getBrowser().browser,
    browser_version: getBrowser().version,
    browser_tab_id: window.name,
    url: window.location.href,
  });

/**
 * Constructs the event message payload.
 */
export const buildEventMessage = (
  eventName: string,
  metadata: Record<string, unknown>
) => ({
    coach_id: Store.me?.id ?? null,
    coach_time_zone: moment.tz.guess(),
    coach_local_time:moment().format('YYYY-MM-DD HH:mm:ss'),
    member_id: metadata.memberId ?? Store.member?.id ?? null,
    utc_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
    event_type: eventName,
    event_info: getEventInfo(metadata),
  });

/**
 * Generates a unique ID by removing dashes from a UUID and appending a timestamp.
 * The resulting format will be: 'ddc61ae3f5d84da59792c8f7d9b9e0ae-YYYYMMDDHHmmss'
 */
export const generateUniqueId = (): string => {
  const uuidWithoutDashes = uuidv4().replace(/-/g, '');

  const now = new Date();
  const timestamp = now
    .toISOString()
    .replace(/[-:.TZ]/g, '')
    .slice(0, 14);

  return `${uuidWithoutDashes}-${timestamp}`;
};
