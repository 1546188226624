import { sharedInMemoryStorage } from '@sibly/sibly-sdk-browser';
import { Environment } from './types';

export const qa1: Environment = {
  name: 'development-qa1',
  env: 'development',
  mock: false,

  hades: {
    host: 'https://hades.qa1.sibly.cloud/v1',
  },

  socket: {
    host: 'wss://6duuet6646.execute-api.us-east-1.amazonaws.com/development-qa1',
  },

  wormhole: {
    host: 'https://wormhole.sibly.care/staging',
    apiKey: 'BwJ1ZQuhCI9hMWEJajDi45QLlrABfAZR4buhiqKW',
    source: 'Sibly.DashEvents-Staging'
  },

  sendbird: {
    appId: 'A44D94BD-99C0-4F26-AEAF-8D4BF69BC180',

    bot: {
      id: 'bot',
    },

    coach: {
      id: 'sibly',
      token: 'd61e10138ece832212624d97ce2e732bfa7bb41e',
    },
  },

  userSnap: {
    apiKey: 'd7368b35-bd23-451c-93a3-d430984be9b0',
  },

  segment: {
    key: 'NGffpp9mD65ezVJC8x78UFZfAmp29VLW',
    track: true,
  },
  // staging key
  split: {
    key: 'k24f71keue19amad2nbqmqrmt4uupqd0l3g5',
  },

  fullStory: {
    orgId: '182P5Q',
    active: true,
  },

  siblySDK: {
    Auth: {
      userPoolId: 'us-west-1_tlijkTiMX',
      userPoolClientId: '7hcre0u3stpedvt0lvlphlvps6',
    },
    transportOptions: {
      region: 'us-west-1',
      endpoint:
        'https://dxokngioijddpaqyt653mh2a7a.appsync-api.us-west-1.amazonaws.com/graphql',
    },
    appId: 'a84a7a19-b6af-4388-850f-eab27dce8d58',
    storage: sharedInMemoryStorage,
  },

  sessionTimeouts: {
    inactivityDetectionTimeout: 30 * 60 * 1000, // 30 minutes
    logoutModalTimeout: 2 * 60 * 1000, // 2 minutes
  },

  nudgeChromeExtensionId: 'npggkpglpapidjoekpmahcdjcpeglgpc',
};
