import { flow } from 'mobx-state-tree';

/**
 * Format access code data for Create or Update APIs
 */
function formatAccessCode(data: any) {
  const {
    activatedAt,
    benefitsUrl,
    code,
    duration,
    durationType,
    expiredAt,
    customMemberType,
    limit,
    organizationId,
  } = data;

  return {
    activatedAt: activatedAt ? new Date(activatedAt) : null,
    benefitsUrl,
    code,
    duration: durationType === 'none' ? null : duration,
    expiredAt: expiredAt ? new Date(expiredAt) : null,
    limit,
    metaData: {
      ...data.metaData,
      customMemberType,
    },
    organizationId: Number(organizationId),
  };
}

export default {
  actions: (self: any) => ({
    // TODO: clean up this function
    addAccessCodes(items = []) {
      self.accessCodes = self.accessCodes.merge(
        [...items.map((c: any) => [c.id, {
          ...c,
          organization: c.organization.id,
        }])],
      );
    },

    createAccessCode: flow(function* createAccessCode(data) {
      const accessCode = formatAccessCode(data);

      const result = yield self.hades.request(
        'accessCode.create',
        accessCode,
      );

      self.accessCodes.set(result.id, {
        ...result,
        organization: result.organizationId,
      });

      return result;
    }),

    updateAccessCode: flow(function* updateAccessCode(data) {
      const accessCode = formatAccessCode(data);
      
      const result = yield self.hades.request(
        'accessCode.update',
        {
          id: data.id,
          ...accessCode,
        },
      );

      self.accessCodes.set(result.id, {
        ...result,
        organization: result.organizationId,
      });

      return result;
    }),

    getRedemptionCounts: flow(function* getRedemptionCounts() {
      const redemptionCounts: Array<{code: string; count: number}> = yield self.hades.get('accessCodes/redemptionCounts');
      return redemptionCounts;
    }),
  }),
};
