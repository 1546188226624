// @ts-nocheck
import { types } from 'mobx-state-tree';
import moment from 'moment-timezone';

import DateTime from '@utils/mst/types/DateTime';

import { isAValidDate } from '@utils/dates';
import Organization from './Organization';

const MetaData = types.model('MetaData', {
  customMemberType: types.optional(types.boolean, false),
  shareable: types.maybeNull(types.boolean),
  audience: types.maybeNull(types.string),
});

const AccessCode = types
  .model('AccessCode', {
    id: types.identifierNumber,
    activatedAt: types.maybeNull(DateTime),
    benefitsUrl: types.maybeNull(types.string),
    code: types.string,
    duration: types.maybeNull(types.number),
    expiredAt: types.maybeNull(DateTime),
    isEligibilityCode: types.maybeNull(types.boolean),
    limit: types.maybeNull(types.number),
    metaData: types.maybeNull(MetaData),
    organization: types.reference(Organization),
  })
  .views((self) => ({
    get activatedAtMoment() {
      if (!isAValidDate(self.activatedAt)) return null;

      return moment(self.activatedAt).format('MM-DD-YYYY');
    },

    get expiredAtMoment() {
      if (!isAValidDate(self.expiredAt)) return null;

      return moment(self.expiredAt).format('MM-DD-YYYY');
    },

    get hasCustomMemberType() {
      return Boolean(self.metaData?.customMemberType);
    },

    get hasKKRCustomMemberType() {
      return self.hasCustomMemberType && self.organization.name.includes('KKR');
    },
  }));

export default AccessCode;
