import { sharedInMemoryStorage } from '@sibly/sibly-sdk-browser';
import { Environment } from './types';

export const env9: Environment = {
  name: 'development-env9',
  env: 'development',
  mock: false,

  hades: {
    host: 'https://hades.env9.sibly.cloud/v1',
  },

  socket: {
    host: 'wss://pq66kvbnyf.execute-api.us-east-1.amazonaws.com/development-env9',
  },

  wormhole: {
    host: 'https://wormhole.sibly.care/dev',
    apiKey: '3vLJWZoJ4B3zIh7BYvf3was9iviXiFSE8vFQBwQh',
    source: 'Sibly.DashEvents-Dev'
  },

  sendbird: {
    appId: '4865621F-A391-47D2-9527-3F42D826A492',

    bot: {
      id: 'bot',
    },

    coach: {
      id: 'sibly',
      token: '834abd1fd7c8a80a61dd87640693be3a87b8e8a4',
    },
  },

  userSnap: {
    apiKey: 'd7368b35-bd23-451c-93a3-d430984be9b0',
  },

  segment: {
    key: 'NGffpp9mD65ezVJC8x78UFZfAmp29VLW',
    track: true,
  },

  // staging key
  split: {
    key: 'k24f71keue19amad2nbqmqrmt4uupqd0l3g5',
  },

  fullStory: {
    orgId: '182P5Q',
    active: false,
  },

  siblySDK: {
    Auth: {
      userPoolId: 'us-west-1_zE2AOgirg',
      userPoolClientId: '6f24uqruo57vvpqv521tvnr5c9',
    },
    transportOptions: {
      region: 'us-west-1',
      endpoint:
        'https://vfzvip76kjgu5m6uvl4rqy7xiq.appsync-api.us-west-1.amazonaws.com/graphql',
    },
    appId: 'a84a7a19-b6af-4388-850f-eab27dce8d58',
    storage: sharedInMemoryStorage,
  },

  sessionTimeouts: {
    inactivityDetectionTimeout: 30 * 60 * 1000, // 30 minutes
    logoutModalTimeout: 2 * 60 * 1000, // 2 minutes
  },

  nudgeChromeExtensionId: 'npggkpglpapidjoekpmahcdjcpeglgpc',
};
