import { getHadesError } from '@utils/getCallError';
import axios, { AxiosError, AxiosInstance } from 'axios';
import * as axiosLogger from 'axios-logger';
import { omit } from 'lodash';
import { v4 as UUIDv4 } from 'uuid';
import { SiblySDK } from '@sibly/sibly-sdk-browser';

import mapValuesDeep from '@utils/mapValuesDeep';
import { Logger } from '@utils/logger';
import { IS_PROD } from '../Config';

/**
 * Creates a JSON-RPC envelope with the given method and parameters.
 * @param {string} method - The method name.
 * @param {object} [params={}] - The parameters for the method.
 * @returns {object} The JSON-RPC envelope.
 */
const createEnvelope = (method: string, params: object = {}): object => ({
  method,
  params,
  id: UUIDv4(),
  jsonrpc: '2.0',
});

enum RequestMethods{
  GET= 'GET',
  POST= 'POST'
}

export default class Hades {
  client: AxiosInstance;

  /**
   * Creates an instance of Hades.
   * @param {string} host - The base URL for the Axios client.
   */
  constructor(host: string) {
    this.client = axios.create({ baseURL: host });
    if (!IS_PROD) {
      this.client.interceptors.request.use(
        axiosLogger.requestLogger,
        axiosLogger.errorLogger,
      );
      this.client.interceptors.response.use(
        axiosLogger.responseLogger,
        axiosLogger.errorLogger,
      );
    }
  }

  /**
   * Makes a POST request to the specified path with the given parameters and options.
   * @private
   * @template T
   * @param {string} path - The API endpoint path.
   * @param {object} [params={}] - The parameters for the request.
   * @param {object} [opts={}] - Additional options for the request.
   * @returns {Promise<T>} The response data where null values are converted to undefined.
   * @throws Will throw an error if the request fails.
   */
  private async makeRequest<T = any>(path: string, params: Record<string, any> = {}, opts: Record<string, any> = {}, requestMethod: RequestMethods = RequestMethods.POST): Promise<T> {
    try {
      const method = params?.method ?? path;

      SiblySDK.Monitoring.addBreadcrumb({
        message: method,
        category: 'Hades request',
      });

      const resolvedParams = path === '/'
        ? createEnvelope(method, omit(params, 'method'))
        : params;

      let response;

      if (requestMethod === 'GET') {
        response = await this.client.get(path, opts);
      } if (requestMethod === 'POST'){
        response = await this.client.post(
          path,
          resolvedParams,
          opts,
        );
      }

      // Convert any null values to undefined
      return path === '/' ? mapValuesDeep(response?.data?.result, (val) => val === null ? undefined : val) : response?.data;
    } catch (error) {
      // Log error, convert into a Sibly API error, and throw
      Logger.error(`Hades request failed for method: ${params?.method ?? path}`, error);
      throw getHadesError(error as AxiosError);
    }
  }

  /**
   * Makes a legacy RPC request.
   * @template T
   * @param {string} method - The method name for the RPC request.
   * @param {object} [params={}] - The parameters for the RPC request.
   * @param {object} [opts={}] - Additional options for the request.
   * @returns {Promise<T>} The response data where null values are converted to undefined.
   * @throws Will throw an error if the request fails.
   */
  async request<T = any>(method: string, params: Record<string, any> = {}, opts: Record<string, any> = {}): Promise<T> {
    return this.makeRequest('/', { method, ...params }, opts);
  }

  /**
   * Makes a POST request to the specified path.
   * @template T
   * @param {string} path - The API endpoint path.
   * @param {object} [params={}] - The parameters for the request.
   * @param {object} [opts={}] - Additional options for the request.
   * @returns {Promise<T>} The response data where null values are converted to undefined.
   * @throws Will throw an error if the request fails.
   */
  async post<T = any>(path: string, params: Record<string, any> = {}, opts: Record<string, any> = {}): Promise<T> {
    return this.makeRequest(path, params, opts);
  }

  /**
   * Makes a GET request to the specified path.
   * @template T
   * @param {string} path - The API endpoint path.
   * @param {object} [params={}] - The parameters for the request.
   * @param {object} [opts={}] - Additional options for the request.
   * @returns {Promise<T>} The response data where null values are converted to undefined.
   * @throws Will throw an error if the request fails.
   */
  async get<T = any>(path: string, params: Record<string, any> = {}, opts: Record<string, any> = {}): Promise<T> {
    return this.makeRequest(path, params, opts, RequestMethods.GET);
  }
}
